/* eslint-disable no-underscore-dangle */
import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import Web3 from "web3"

import dexeABI from "ABI/dexe"
import dexeUI from "ABI/dexeUI"
import erc20ABI from "ABI/erc20"
import axios from "axios"

const contractAddress = "0xde4ee8057785a7e8e800db58f9784845a5c2cbd6"
const dexeUIAddress = "0xaa6c61bc850e7a4ecb99d0251b9068c0cbb24aea"
const wusdtAddress = "0x5D13507eaF2E22D1822eBC8011D46D5641bc92Da"

const web3 = new Web3(window.ethereum)

const web3Cloud = new Web3(window.ethereum)

const Context = React.createContext({
  web3,
})

const isAllowanceChecked = localStorage.getItem(
  "is-first-round-allowance-checked"
)

export const useWeb3Context = () => React.useContext(Context)

class Web3Context extends React.Component {
  interval = null

  constructor() {
    super()
    this.state = {
      saleDataLoading: true,
      userDataLoading: false,
      priceFeed: {
        mxcDexeUsdt: "0",
        uniDexeEth: "0",
        uniDexeUsdc: "0",
      },
      apy: "0.00",
      priceOnMXC: 0,
      apyFirst: "0.00",
      apyFirstX2: "0.00",
      apySecond: "0.00",
      apySecondX2: "0.00",
      exchanging: false,
      claming: false,
      unlocking: false,
      Dexe: new web3.eth.Contract(dexeABI, contractAddress),
      DexeUI: new web3.eth.Contract(dexeUI, dexeUIAddress),
      DexeCloud: new web3Cloud.eth.Contract(dexeABI, contractAddress),
      DexeUICloud: new web3Cloud.eth.Contract(dexeUI, dexeUIAddress),
      WUSDT: new web3.eth.Contract(erc20ABI, wusdtAddress),
      depositRound: "0",
      ongoingRoundPrice: "0.0000",
      roundComplete: 0,
      totalDeposited: 0,
      priceUSDT: "0",
      priceETH: "0",
      allRounds: [
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
        { roundPrice: "0", totalDeposited: "0" },
      ],
      UNIcurrentPrice: "0",
      address: "",
      totalSupply: "100000000000000000000000000",
      discount: "0",
      balances: {
        eth: 0,
        usdt: 0,
        usdc: 0,
      },
      holderInfo: {
        holderSaleAverage: 0,
        _isWhitelisted: false,
        _info: {
          firstRoundDeposited: "0",
        },
        _locks: [
          { balance: "0", released: "0" },
          { balance: "0", released: "0" },
          { balance: "0", released: "0" },
          { balance: "0", released: "0" },
          { balance: "0", released: "0" },
          { balance: "0", released: "0" },
        ],
        _rounds: [],
        balanceOf: "0",
        averageBalance: "0",
        firstBalanceChange: "0",
      },
      holderInfoUI: ["0", "0", "0", "0", "0", "0", "0", "0"],
      claimableBalance: {
        0: "0",
        1: "0",
        2: "0",
      },
      firstRoundChecked: !!isAllowanceChecked,
      allowance: 0,
      dexeContractBalance: "0",
      missingDexeForX2: "0",
    }
  }

  componentDidMount = async () => {
    this.getData()

    this.interval = setInterval(() => {
      this.getData()
      this.getFullHolderInfo()
    }, 1000 * 30)

    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length) {
        this.getAccounts()
      } else {
        this.disconnect()
      }
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getPriceFeed = async () => {
    try {
      const response = await axios.get("https://price-feed.dexe.network/price")
      const priceFeed = response.data.data

      const mxcDexeUsdt = priceFeed.MXC_DEXE_USDT || "0"
      const uniDexeEth = priceFeed.UNI_DEXE_ETH || "0"
      const uniDexeUsdc = priceFeed.UNI_DEXE_USDC || "0"

      return {
        mxcDexeUsdt,
        uniDexeEth,
        uniDexeUsdc,
      }
    } catch (e) {
      console.log(e)
      return {
        mxcDexeUsdt: "0",
        uniDexeEth: "0",
        uniDexeUsdc: "0",
      }
    }
  }

  fetchCommonData = () => {
    const { DexeCloud, DexeUICloud } = this.state

    const dexeContract = DexeCloud
    const dexeUIContract = DexeUICloud

    try {
      return Promise.all([
        dexeContract.methods.getAllRounds().call(),
        dexeContract.methods.totalSupply().call(),
        dexeUIContract.methods.uniPrice().call(),
        dexeContract.methods.balanceOf(contractAddress).call(),
      ])
    } catch (e) {
      // TODO: try cloudflare method
      // TODO: try infura method
      return null
    }
  }

  getData = async () => {
    // const { Dexe, DexeUI } = this.state

    try {
      const [
        allRounds,
        totalSupply,
        UNIcurrentPrice,
        dexeContractBalance,
      ] = await this.fetchCommonData()

      const priceFeed = await this.getPriceFeed()
      const priceOnMXC = 0

      const data = {
        saleDataLoading: false,
        priceOnMXC,
        UNIcurrentPrice,
        allRounds,
        totalSupply,
        dexeContractBalance,
        priceFeed,
      }

      this.setState(data, () => {
        if (localStorage.getItem("dexe-network-explorer-authstate") === "1") {
          this.getAccounts()
        }
      })
    } catch (e) {
      console.log(e)

      this.setState({
        saleDataLoading: false,
      })
    }
  }

  // Get holder info by address from metamask
  getFullHolderInfo = async () => {
    const { address, DexeCloud, DexeUICloud } = this.state

    const Dexe = DexeCloud
    const DexeUI = DexeUICloud

    if (address.length === 42) {
      try {
        const holderInfo = await Dexe.methods.getFullHolderInfo(address).call()

        const firstBalanceChange = await Dexe.methods
          .firstBalanceChange(address)
          .call()

        const balanceOf = await Dexe.methods.balanceOf(address).call()

        const claimableBalance = await DexeUI.methods
          .holderClaimableBalance(address)
          .call()

        const holderSaleAverage = await DexeUI.methods
          .holderSaleAverage(address)
          .call()

        holderInfo.balanceOf = balanceOf
        holderInfo.firstBalanceChange = firstBalanceChange

        holderInfo.firstDeposit = "0"
        holderInfo.holderSaleAverage = holderSaleAverage

        holderInfo._rounds.map((round, index) => {
          if (round.deposited !== "0" && holderInfo.firstDeposit === "0") {
            holderInfo.firstDeposit = index + 1
          }
        })

        const missingDexeForX2 = Math.ceil(
          Number(claimableBalance[3]) / 10 ** 18
        )

        this.setState({
          userDataLoading: false,
          holderInfo,
          claimableBalance,
          missingDexeForX2,
        })
      } catch (e) {
        console.log(e)

        this.setState({
          userDataLoading: false,
        })
      }
    } else {
      this.setState({
        userDataLoading: false,
      })
    }
  }

  // get metamask connected adderss, save it to the state and localstorage
  getAccounts = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    })

    if (accounts.length) {
      const address = accounts[0]
      this.setUserAddress(address)
      localStorage.setItem("dexe-network-explorer-authstate", "1")
    }
  }

  // Set address when metamask connected
  setUserAddress = (address) => {
    this.setState(
      {
        userDataLoading: true,
        address,
      },
      this.getFullHolderInfo
    )
  }

  disconnect = async () => {
    localStorage.removeItem("dexe-network-explorer-authstate")
    this.setState(
      {
        saleDataLoading: true,
        userDataLoading: false,
        apy: "0.00",
        priceOnMXC: 0,
        apyFirst: "0.00",
        apyFirstX2: "0.00",
        apySecond: "0.00",
        apySecondX2: "0.00",
        exchanging: false,
        claming: false,
        unlocking: false,
        depositRound: 22,
        ongoingRoundPrice: "0.0000",
        roundComplete: 0,
        totalDeposited: 0,
        priceUSDT: "0",
        priceETH: "0",
        allRounds: [
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
          { roundPrice: "0", totalDeposited: "0" },
        ],
        UNIcurrentPrice: "0",
        address: "",
        totalSupply: "100000000000000000000000000",
        discount: "0",
        balances: {
          eth: 0,
          usdt: 0,
          usdc: 0,
        },
        holderInfo: {
          holderSaleAverage: 0,
          _isWhitelisted: false,
          _info: {
            firstRoundDeposited: "0",
          },
          _locks: [
            { balance: "0", released: "0" },
            { balance: "0", released: "0" },
            { balance: "0", released: "0" },
            { balance: "0", released: "0" },
            { balance: "0", released: "0" },
            { balance: "0", released: "0" },
          ],
          _rounds: [],
          balanceOf: "0",
          averageBalance: "0",
          firstBalanceChange: "0",
        },
        holderInfoUI: ["0", "0", "0", "0", "0", "0", "0", "0"],
        claimableBalance: {
          0: "0",
          1: "0",
          2: "0",
        },
        firstRoundChecked: !!isAllowanceChecked,
        allowance: 0,
        dexeContractBalance: "0",
        missingDexeForX2: "0",
      },
      () => this.setUserAddress("")
    )
  }

  claim = async () => {
    const { address, Dexe } = this.state

    this.setState({ claming: true })
    try {
      await Dexe.methods.receiveAll().send({ from: address })
    } catch (e) {
      console.log(e)
    }

    this.setState({ claming: false })
    this.getData()
    this.getFullHolderInfo()
  }

  unlock = async (type = 0) => {
    const { address, Dexe } = this.state

    this.setState({ unlocking: true })

    try {
      await Dexe.methods.releaseLock(type).send({ from: address })
    } catch (e) {
      console.log(e)
    }

    this.setState({ unlocking: false })
    this.getData()
    this.getFullHolderInfo()
  }

  render() {
    const { children } = this.props
    const {
      saleDataLoading,
      userDataLoading,
      Dexe,
      depositRound,
      allRounds,
      UNIcurrentPrice,
      address,
      holderInfo,
      totalSupply,
      firstRoundChecked,
      allowance,
      balances,
      ongoingRoundPrice,
      priceETH,
      priceUSDT,
      WUSDT,
      exchanging,
      dexeContractBalance,
      holderInfoUI,
      claming,
      claimableBalance,
      roundComplete,
      discount,
      apy,
      apyFirst,
      apyFirstX2,
      apySecond,
      apySecondX2,
      missingDexeForX2,
      totalDeposited,
      unlocking,
      priceOnMXC,
      priceFeed,
    } = this.state

    return (
      <Context.Provider
        value={{
          saleDataLoading,
          userDataLoading,
          web3,
          address,
          Dexe,
          depositRound,
          allRounds,
          UNIcurrentPrice,
          holderInfo,
          totalSupply,
          firstRoundChecked,
          allowance,
          balances,
          ongoingRoundPrice,
          priceUSDT,
          priceETH,
          WUSDT,
          exchanging,
          dexeContractBalance,
          holderInfoUI,
          claming,
          claimableBalance,
          roundComplete,
          discount,
          apy,
          apyFirst,
          apyFirstX2,
          apySecond,
          apySecondX2,
          missingDexeForX2,
          totalDeposited,
          unlocking,
          priceOnMXC,
          priceFeed,

          setUserAddress: this.setUserAddress,
          getAccounts: this.getAccounts,
          disconnect: this.disconnect,
          setAllocationChecked: this.setAllocationChecked,
          sendDeposit: this.sendDeposit,
          claim: this.claim,
          unlock: this.unlock,
        }}
      >
        {children}
      </Context.Provider>
    )
  }
}

Web3Context.propTypes = {
  children: PropTypes.element.isRequired,
}

export default withRouter(Web3Context)
