/* eslint-disable camelcase */
const roundStartList = [
  "2020-09-28Z12:00:00",
  "2020-09-29Z12:00:00",
  "2020-09-30Z12:00:00",

  "2020-10-01Z12:00:00",
  "2020-10-02Z12:00:00",
  "2020-10-03Z12:00:00",
  "2020-10-04Z12:00:00",
  "2020-10-05Z12:00:00",
  "2020-10-06Z12:00:00",
  "2020-10-07Z12:00:00",
  "2020-10-08Z12:00:00",
  "2020-10-09Z12:00:00",
  "2020-10-10Z12:00:00",
  "2020-10-11Z12:00:00",
  "2020-10-12Z12:00:00",
  "2020-10-13Z12:00:00",
  "2020-10-14Z12:00:00",
  "2020-10-15Z12:00:00",
  "2020-10-16Z12:00:00",
  "2020-10-17Z12:00:00",
  "2020-10-18Z12:00:00",
  "2020-10-19Z12:00:00",
  "2020-10-20Z12:00:00",
]

const roundCompletePercent = () => {
  return 0
}

const getDateDiff = (date) => {
  const date1 = new Date(date)
  const date2 = new Date()

  const Difference_In_Time = date2.getTime() - date1.getTime()

  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

  const fullDays = Math.floor(Difference_In_Time / (1000 * 3600) / 24)

  const fullHours = Difference_In_Time / (1000 * 3600) - fullDays * 24

  return isNaN(fullDays)
    ? "0d 0h"
    : `${fullDays.toFixed(0)}d ${Math.floor(fullHours).toFixed(0)}h`
}

const isOneHourLeft = (date) => {
  const date1 = new Date(date)
  const date2 = new Date()

  const Difference_In_Time = date2.getTime() - date1.getTime()

  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) / 24

  return Difference_In_Days
}

export { roundCompletePercent, getDateDiff, isOneHourLeft, roundStartList }
