/* eslint-disable no-underscore-dangle */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useWeb3Context } from "context/Web3Context"
import ReactTooltip from "react-tooltip"
import { getDateDiff, roundStartList } from "utils/calculations"
import "./style.scss"

const Row = ({ label, value, value2, highlight = false, green = false }) => (
  <div className={`row ${green && "row_green"}`}>
    <div className="row__label">{label}</div>
    <div className={`row__value ${highlight && "row__value_active"}`}>
      {value}{" "}
      {value2 ? (
        <>
          <span data-tip data-for={value2} className="row__value_red">
            {value2}
          </span>
          <ReactTooltip place="top" id={value2}>
            Yearly APY
          </ReactTooltip>
        </>
      ) : null}
    </div>
  </div>
)

export default function MyStatistic() {
  const { holderInfo } = useWeb3Context()

  const [kef, setKef] = useState(13)

  const getKef = () => {
    const avBal = holderInfo.holderSaleAverage
    const curBal = holderInfo.balanceOf

    if (kef === 3) {
      const val = avBal - curBal
      const res = val <= 0 ? 0 : val
      return Number(res / 10 ** 18).toFixed(4)
    }
    if (kef === 4) {
      const val = avBal * 1.01 - curBal
      const res = val <= 0 ? 0 : val
      return Number(res / 10 ** 18).toFixed(4)
    }
    if (kef === 6) {
      const val = avBal * 1.25 - curBal
      const res = val <= 0 ? 0 : val
      return Number(res / 10 ** 18).toFixed(4)
    }
    if (kef === 9) {
      const val = avBal * 1.5 - curBal
      const res = val <= 0 ? 0 : val
      return Number(res / 10 ** 18).toFixed(4)
    }
    if (kef === 13) {
      const val = avBal * 1.75 - curBal
      const res = val <= 0 ? 0 : val
      return Number(res / 10 ** 18).toFixed(4)
    }
    return 0
  }

  const balance =
    Number(holderInfo._locks[0].balance / 10 ** 18) +
    Number(holderInfo._locks[1].balance / 10 ** 18) +
    Number(holderInfo._locks[2].balance / 10 ** 18) +
    Number(holderInfo._locks[3].balance / 10 ** 18) +
    Number(holderInfo._locks[4].balance / 10 ** 18) +
    Number(holderInfo._locks[5].balance / 10 ** 18) -
    (Number(holderInfo._locks[0].released / 10 ** 18) +
      Number(holderInfo._locks[1].released / 10 ** 18) +
      Number(holderInfo._locks[2].released / 10 ** 18) +
      Number(holderInfo._locks[3].released / 10 ** 18) +
      Number(holderInfo._locks[4].released / 10 ** 18) +
      Number(holderInfo._locks[5].released / 10 ** 18))

  const holdDays =
    holderInfo._info &&
    holderInfo._info.firstRoundDeposited &&
    holderInfo._info.firstRoundDeposited !== "0"
      ? getDateDiff(roundStartList[holderInfo._info.firstRoundDeposited])
      : "0d 0h"

  return (
    <div className="my-statistic">
      <Row
        label="First DeXe was received in the round:"
        value={holderInfo._info.firstRoundDeposited}
      />

      <Row
        label="Average DeXe Hold Volume:"
        value={`${(holderInfo.holderSaleAverage / 10 ** 18).toFixed(4)} DeXe`}
      />
      <div className="custom-row row">
        <div className="row__label">
          For x{kef}, purchase:
          <div className="row__actions">
            <div
              className={`row__action ${kef === 3 ? "row__action_active" : ""}`}
              onClick={() => setKef(3)}
            >
              x3
            </div>
            <div
              className={`row__action ${kef === 4 ? "row__action_active" : ""}`}
              onClick={() => setKef(4)}
            >
              x4
            </div>
            <div
              className={`row__action ${kef === 6 ? "row__action_active" : ""}`}
              onClick={() => setKef(6)}
            >
              x6
            </div>
            <div
              className={`row__action ${kef === 9 ? "row__action_active" : ""}`}
              onClick={() => setKef(9)}
            >
              x9
            </div>
            <div
              className={`row__action ${
                kef === 13 ? "row__action_active" : ""
              }`}
              onClick={() => setKef(13)}
            >
              x13
            </div>
          </div>
        </div>

        <div className="row__value">{getKef()} DeXe</div>
      </div>

      <Row label="DeXe hold days:" value={holdDays} />

      <Row
        label="Wallet locked balance:"
        value={`${balance.toFixed(4)} DeXe`}
      />
      <Row
        label="Wallet unlocked balance:"
        value={`${(holderInfo.balanceOf / 10 ** 18).toFixed(4)} DeXe`}
      />
    </div>
  )
}

Row.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}
