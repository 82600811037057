import React from "react"

import { useWeb3Context } from "context/Web3Context"
import Countdown from "react-countdown"

import { BarsSpinner } from "react-spinners-kit"
import { toast } from "react-toastify"
import ReactTooltip from "react-tooltip"

import dates from "./dates"
import "./style.scss"

const tip =
  "The claim of DeXe tokens process  <br/> requires the commission of the ETH network. <br/> Please set at least 22 gwei <br/> and wait for the process to complete"

const CD = ({ hours, minutes, seconds, completed }) => {
  if (hours >= 1 || completed) {
    return null
  }

  if (isNaN(hours)) {
    return null
  }

  return (
    <>
      <div className="withdraw__countdown-label">
        Tokens of the previous round will be distributed in:{" "}
      </div>
      <span className="withdraw__time">
        {hours < 9 ? `0${hours}` : hours}:
        {minutes < 9 ? `0${minutes}` : minutes}:
        {seconds < 9 ? `0${seconds}` : seconds}
      </span>
    </>
  )
}

export default function Withdraw() {
  const {
    claimableBalance,
    holderInfo,
    claim,
    claming,
    depositRound,
  } = useWeb3Context()

  const balance =
    Number(holderInfo._locks[0].balance / 10 ** 18) +
    Number(holderInfo._locks[1].balance / 10 ** 18) +
    Number(holderInfo._locks[2].balance / 10 ** 18) +
    Number(holderInfo._locks[3].balance / 10 ** 18) +
    Number(holderInfo._locks[4].balance / 10 ** 18) +
    Number(holderInfo._locks[5].balance / 10 ** 18) -
    (Number(holderInfo._locks[0].released / 10 ** 18) +
      Number(holderInfo._locks[1].released / 10 ** 18) +
      Number(holderInfo._locks[2].released / 10 ** 18) +
      Number(holderInfo._locks[3].released / 10 ** 18) +
      Number(holderInfo._locks[4].released / 10 ** 18) +
      Number(holderInfo._locks[5].released / 10 ** 18))

  const isClaimable = claimableBalance["0"] + claimableBalance["1"] > 0
  return (
    <div className="withdraw">
      <div className="withdraw__cell">
        <div className="withdraw__label">Available for withdrawal DeXe</div>
        <div className="withdraw__value">
          {(claimableBalance["0"] / 10 ** 18).toFixed(4)}
        </div>
      </div>
      <div className="withdraw__cell">
        <div className="withdraw__label">
          Available for withdrawal locked DeXe
        </div>
        <div className="withdraw__value">
          {(claimableBalance["1"] / 10 ** 18).toFixed(4)}
        </div>
      </div>
      <div className="withdraw__countdown">
        <Countdown
          renderer={CD}
          date={new Date(dates[depositRound !== "0" ? depositRound - 2 : 0])}
        />
      </div>
      <div
        className={`withdraw__button ${
          !isClaimable ? "withdraw__button_disabled" : ""
        }`}
        onClick={
          isClaimable
            ? claim
            : () => {
                toast("You have no available DeXe for withdrawal", {
                  type: toast.TYPE.DARK,
                })
              }
        }
      >
        {claming ? (
          <BarsSpinner size={40} color="#686769" loading />
        ) : (
          <span data-tip={tip}>Claim</span>
        )}
      </div>

      <div style={{ maxWidth: 200 }}><ReactTooltip multiline /></div>
    </div>
  )
}
