import React from "react"

import MyStatistic from "components/my-statistic/MyStatistic"
import TokenStatistic from "components/token-statistic/TokenStatistic"
import BarChart from "components/bar-chart/BarChart"
import Withdraw from "components/withdraw/Withdraw"
import BalancesCard from "components/BalancesCard"
import Statistic from "components/statistic"

import "./style.scss"

const Home = () => {
  return (
    <div className="home">
      <Statistic />
      {/* <RoundProgress /> */}

      <div className="home__wrapper">
        <div className="home__asside">
          {/* <Exchange /> */}
          <Withdraw />
          <BalancesCard />
          <div className="home__links-container">
            <div className="home__link">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://etherscan.io/address/0xde4EE8057785A7e8e800Db58F9784845A5C2Cbd6"
              >
                Contract address
              </a>
            </div>
            <div className="home__link">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.mxc.com/trade/pro#DEXE_USDT"
              >
                Trade on MXC
              </a>
            </div>
            <div className="home__link">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://info.uniswap.org/pair/0x11cb9e028b82eee75443fdc27929b9c49736c552"
              >
                Charts DeXe
              </a>
            </div>
          </div>
        </div>
        <div className="home__card">
          <BarChart />
          <MyStatistic />
          <TokenStatistic />
        </div>
      </div>
    </div>
  )
}

export default Home
