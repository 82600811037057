import * as React from "react"

function Unlock(props) {
  return (
    <svg viewBox="0 0 426.841 426.841" {...props}>
      <path
        d="M333.584 123.298c0-32.914-12.539-63.739-35.527-86.727C276.114 14.106 244.767 1.045 212.376 0c-45.453 0-87.771 26.122-108.147 67.396-3.657 7.837-.522 17.241 6.792 20.898 7.837 3.657 17.241.522 20.898-6.792 15.151-30.302 46.498-49.633 79.935-49.633 24.033.522 47.543 9.927 63.739 26.645 16.718 16.718 26.122 39.706 26.122 64.784v38.661h31.347l.522-38.661z"
        fill="#3a2c51"
      />
      <path
        d="M349.257 160.392H77.584c-14.629 0-26.122 11.494-26.122 26.122v214.204c0 14.629 11.494 26.122 26.122 26.122h271.673c14.629 0 26.122-11.494 26.122-26.122V186.514c.001-14.628-11.493-26.122-26.122-26.122z"
        fill="#4dcfe0"
      />
      <path
        d="M229.094 327.576v-36.571c0-8.882-6.792-15.673-15.673-15.673s-15.673 6.792-15.673 15.673v36.571c0 8.882 6.792 15.673 15.673 15.673s15.673-6.792 15.673-15.673z"
        fill="#00bbd3"
      />
    </svg>
  )
}

const MemoUnlock = React.memo(Unlock)
export default MemoUnlock
