const dates = [
  "2020-09-29Z12:00:00",
  "2020-09-30Z12:00:00",

  "2020-10-01Z12:00:00",
  "2020-10-02Z12:00:00",
  "2020-10-03Z12:00:00",
  "2020-10-04Z12:00:00",
  "2020-10-05Z12:00:00",
  "2020-10-06Z12:00:00",
  "2020-10-07Z12:00:00",
  "2020-10-08Z12:00:00",
  "2020-10-09Z12:00:00",
  "2020-10-10Z12:00:00",
  "2020-10-11Z12:00:00",
  "2020-10-12Z12:00:00",
  "2020-10-13Z12:00:00",
  "2020-10-14Z12:00:00",
  "2020-10-15Z12:00:00",
  "2020-10-16Z12:00:00",
  "2020-10-17Z12:00:00",
  "2020-10-18Z12:00:00",
  "2020-10-19Z12:00:00",
  "2020-10-20Z12:00:00",
]

export default dates
