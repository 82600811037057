import React from "react"
import PropTypes from "prop-types"

import "./style.scss"
import { useWeb3Context } from "context/Web3Context"

const Row = ({ value, label }) => (
  <div className="row">
    <div className="row__value">{value}</div>
    <div className="row__label">{label}</div>
  </div>
)

export default function Statistic() {
  const { priceFeed } = useWeb3Context()

  if (!priceFeed) {
    return null
  }

  const DEXE_USDT = (
    <span>
      Current price for{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.mxc.com/trade/pro#DEXE_USDT"
      >
        DEXE/USDT
      </a>{" "}
      on MXC
    </span>
  )

  const DEXE_ETH = (
    <span>
      Current price for{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xde4ee8057785a7e8e800db58f9784845a5c2cbd6"
      >
        DEXE/ETH
      </a>{" "}
      on UNI
    </span>
  )

  return (
    <div className="statistic">
      <div className="statistic__item">
        <Row
          value={Number(priceFeed.mxcDexeUsdt).toFixed(4)}
          label={DEXE_USDT}
        />
      </div>
      <div className="statistic__item">
        <Row value={Number(priceFeed.uniDexeEth).toFixed(4)} label={DEXE_ETH} />
      </div>
    </div>
  )
}

Row.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.element.isRequired,
}
