import React, { useState } from "react"

import { Link } from "react-router-dom"
import { motion, AnimatePresence } from "framer-motion"
import { useWindowScroll } from "react-use"
import { useWeb3Context } from "context/Web3Context"
import Countdown from "react-countdown"

import { ImpulseSpinner } from "react-spinners-kit"

import roundDateList from "./roundEndDates"
import CountdownRenderer from "./Countdown"
import "./style.scss"

export default function Header() {
  const [sticky, setSticky] = useState(false)
  const { y } = useWindowScroll()

  const {
    getAccounts,
    address,
    disconnect,
    UNIcurrentPrice,
    allowance,
    depositRound,
    saleDataLoading,
    userDataLoading,
  } = useWeb3Context()
  const authorized = !!address.length

  const connectButton = (
    <li className="menu__button menu__button_active" onClick={getAccounts}>
      Connect wallet
    </li>
  )
  const disconnectButton = (
    <>
      <li className="menu__address">{address}</li>
      <li className="menu__button menu__button_bold" onClick={disconnect}>
        Disconnect
      </li>
    </>
  )

  if (y > 20 && !sticky) {
    setSticky(true)
  }
  if (y < 19 && sticky) {
    setSticky(false)
  }

  const counter =
    depositRound !== "0" ? (
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, type: "tween" }}
          className="header__round-progress"
        >
          <Countdown
            renderer={CountdownRenderer}
            date={new Date(roundDateList[depositRound])}
          />
        </motion.div>
      </AnimatePresence>
    ) : (
      <div />
    )

  return (
    <div className={`header ${sticky && "header__sticky"}`}>
      <div className="wrapper">
        <div className="header__left">
          <div className="logo">
            <a
              href="https://dexe.network"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/logo.svg" alt="DeXe" />
            </a>
          </div>
          <Link to="/">
            <div className="logo__label">EXPLORER</div>
          </Link>
        </div>
        <div className="header__center">
          {saleDataLoading || userDataLoading ? (
            <div className="header__loader">
              <div className="header__loading-label">Loading</div>
              <ImpulseSpinner color="#FEA500" loading />
            </div>
          ) : null}
        </div>
        <div className="header__right">
          <nav className="menu">
            <ul>{!authorized ? connectButton : disconnectButton}</ul>
          </nav>
        </div>
      </div>
    </div>
  )
}
