import React, { useEffect, useState } from "react"
import { Switch, Route, useLocation, Redirect } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { AnimatePresence } from "framer-motion"

import Home from "pages/home"
import Header from "components/header/Header"
import Web3Context from "context/Web3Context"
import { useKeyPressEvent } from "react-use"
import { useWeb3Context } from "context/Web3Context"

import fox from "assets/fox.svg"

import "react-toastify/dist/ReactToastify.css"
import "./App.css"

const AppWrapper = () => {
  const location = useLocation()
  const { setUserAddress } = useWeb3Context()

  useEffect(() => {
    const keyDownListener = document.addEventListener("keydown", (event) => {
      if (
        event.key === "d" &&
        localStorage.getItem("dexe-explorer-open") === "true"
      ) {
        const address = prompt("Address")

        if (typeof address === "string" && address.length === 42) {
          setUserAddress(address)
        }
      }
    })

    return () => {
      document.removeEventListener("keydown", keyDownListener)
    }
  }, [])

  return (
    <>
      <Header />
      <AnimatePresence>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/">
            <Home />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AnimatePresence>
    </>
  )
}

function App() {
  const isWeb3Installed = window.web3 && "currentProvider" in window.web3

  const app = (
    <div className="App">
      <Web3Context>
        <AppWrapper />
      </Web3Context>
      <ToastContainer hideProgressBar />
    </div>
  )

  return isWeb3Installed ? (
    app
  ) : (
    <div className="App">
      <div className="install-metamask">
        <div className="fox-container">
          <img src={fox} alt="" className="fox" />
        </div>
        <div className="container">
          <div className="install-metamask__title">PLEASE INSTALL METAMASK</div>
          <div className="install-metamask__description">
            Dexe require Metamask for functioning, please install Metamask to
            continue
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
