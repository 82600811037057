/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

import { useWeb3Context } from "context/Web3Context"
import { CircleSpinner } from "react-spinners-kit"

import "./style.scss"
import MemoUnlock from "./Unlock"

const secondsBetweenDates = (
  t1 = new Date("2020-10-20Z12:05:00"),
  t2 = new Date()
) => {
  const dif = t1.getTime() - t2.getTime()

  const Seconds_from_T1_to_T2 = dif / 1000
  const Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2)

  return Seconds_Between_Dates
}

const getNameByType = (type) => {
  if (type === 0) {
    return "Staking"
  }
  if (type === 1) {
    return "Foundation"
  }
  if (type === 2) {
    return "Team"
  }
  if (type === 3) {
    return "Partnership"
  }
  if (type === 4) {
    return "School"
  }
  if (type === 5) {
    return "Marketing"
  }
}

const getVestingTimeTotal = (type) => {
  if (type === 0) {
    return 365 * 86400
  }
  if (type === 1) {
    return 1460 * 86400
  }
  if (type === 2) {
    return 730 * 86400
  }
  if (type === 3) {
    return 365 * 86400
  }
  if (type === 4) {
    return 60 * 86400
  }
  if (type === 5) {
    return 365 * 86400
  }
}

const getDaysNeededToUnlock = (type) => {
  if (type === 0) {
    return 0
  }
  if (type === 1) {
    return 365
  }
  if (type === 2) {
    return 180
  }
  if (type === 3) {
    return 90
  }
  if (type === 4) {
    return 60
  }
  if (type === 5) {
    return 30
  }
}

const getUnlockDate = (type) => {
  if (type === 0) {
    return true
  }
  if (type === 5) {
    const dateUnlockStart = new Date("2020-11-20Z12:05:00").getTime()
    const currentDate = new Date().getTime()

    return dateUnlockStart < currentDate
  }
}

const UnlockItem = ({ type, vestingTimePassedSinceCliff }) => {
  const { holderInfo, unlock, unlocking, claimableBalance } = useWeb3Context()
  const name = getNameByType(type)

  const { balance } = holderInfo._locks[type]
  const { released } = holderInfo._locks[type]
  const locked = (balance - released) / 10 ** 18
  const lockedUI = locked < 0 ? "0.000000" : locked.toFixed(6)

  const vestingTimeTotal = getVestingTimeTotal(type)
  const daysNeed = getDaysNeededToUnlock(type)
  const canUnclock =
    (balance * (vestingTimePassedSinceCliff - daysNeed * 86400)) /
      vestingTimeTotal -
    released

  const unlockDatePassed = getUnlockDate(type)
  const toUnlock = canUnclock / 10 ** 18
  const canUnlockUI =
    toUnlock <= 0 || !unlockDatePassed ? "0.000000" : toUnlock.toFixed(6)

  const isClaimable = claimableBalance["0"] + claimableBalance["1"] > 0

  const unlockDisabled = isClaimable || canUnclock === 0 || !unlockDatePassed

  const handleUnlock = () => {
    if (!unlocking && !unlockDisabled) {
      unlock(type)
    }
  }

  if (lockedUI === "0.000000") {
    return null
  }

  return (
    <div className="balances-card__cell">
      <div className="balances-card__label">{name} DeXe:</div>
      <div className="balances-card__combined-values">
        <div className="balances-card__value">
          <div>
            {lockedUI.substring(0, lockedUI.length - 2)}
            <img src="/dexe-token.svg" alt="" />
          </div>
          <div className="balances-card__small-description">Locked</div>
        </div>
        <div className="balances-card__value">
          <div>
            {type === 0
              ? lockedUI.substring(0, lockedUI.length - 2)
              : canUnlockUI.substring(0, canUnlockUI.length - 2)}{" "}
            <img src="/dexe-token.svg" alt="" />
          </div>
          <div className="balances-card__small-description">can unlock</div>
        </div>

        <div
          onClick={handleUnlock}
          className={
            unlockDisabled
              ? "balances-card__icon-button balances-card__icon-button_disabled"
              : "balances-card__icon-button"
          }
        >
          {unlocking ? (
            <CircleSpinner size={15} color="#686769" loading />
          ) : (
            <MemoUnlock />
          )}
        </div>
      </div>
    </div>
  )
}

export default function BalancesCard() {
  const { holderInfo, claimableBalance } = useWeb3Context()
  const [vestingTimePassedSinceCliff, setSecs] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const seconds = secondsBetweenDates()
      setSecs(seconds)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const isClaimable = claimableBalance["0"] + claimableBalance["1"] > 0

  const balanceOf = holderInfo.balanceOf / 10 ** 18
  const balanceUI = balanceOf < 0 ? "0.000000" : balanceOf.toFixed(6)

  return (
    <div className="balances-card">
      {/* TOTAL */}
      <div className="balances-card__cell">
        <div className="balances-card__label">Your balance:</div>
        <div className="balances-card__value">
          {" "}
          {balanceUI.substring(0, balanceUI.length - 2)}{" "}
          <img src="/dexe-token.svg" alt="" />
        </div>
      </div>

      <UnlockItem
        vestingTimePassedSinceCliff={vestingTimePassedSinceCliff}
        type={0}
      />
      {/* <UnlockItem vestingTimePassedSinceCliff={vestingTimePassedSinceCliff} type={1} />
      <UnlockItem vestingTimePassedSinceCliff={vestingTimePassedSinceCliff} type={2} />
      <UnlockItem vestingTimePassedSinceCliff={vestingTimePassedSinceCliff} type={3} />
      <UnlockItem vestingTimePassedSinceCliff={vestingTimePassedSinceCliff} type={4} /> */}
      <UnlockItem
        vestingTimePassedSinceCliff={vestingTimePassedSinceCliff}
        type={5}
      />

      <div className="balances-card__cell">
        {isClaimable ? (
          <div className="balances-card__message">Claim before unlocking</div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
