const roundDateList = [
  "2020-09-29Z11:00:00",
  "2020-09-30Z11:00:00",

  "2020-10-01Z11:00:00",
  "2020-10-02Z11:00:00",
  "2020-10-03Z11:00:00",
  "2020-10-04Z11:00:00",
  "2020-10-05Z11:00:00",
  "2020-10-06Z11:00:00",
  "2020-10-07Z11:00:00",
  "2020-10-08Z11:00:00",
  "2020-10-09Z11:00:00",
  "2020-10-10Z11:00:00",
  "2020-10-11Z11:00:00",
  "2020-10-12Z11:00:00",
  "2020-10-13Z11:00:00",
  "2020-10-14Z11:00:00",
  "2020-10-15Z11:00:00",
  "2020-10-16Z11:00:00",
  "2020-10-17Z11:00:00",
  "2020-10-18Z11:00:00",
  "2020-10-19Z11:00:00",
  "2020-10-20Z11:00:00",
  "2020-10-21Z11:00:00",
]

export default roundDateList
