/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from "react"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"

import { separateNum } from "utils/helpers"
import { useWeb3Context } from "context/Web3Context"

import "./style.scss"

const getHeight = (value, id) => {
  if (Number(value) > 100) {
    return 100
  }

  if (id === "2" && value !== "0.00") {
    return Number(value) + 45
  }
  if (Number(id) > 2 && value !== "0.00") {
    return Number(value) + 20
  }
  return value
}

const Bar = ({ id, data }) => {
  const height = getHeight(data.percent, id)

  return (
    <>
      <div className="bar" data-tip data-for={id}>
        <div className="bar__filled" style={{ top: `${100 - height - 1}%` }} />
      </div>

      <ReactTooltip place="left" id={id}>
        <div className="tooltip__content">
          <div className="tooltip__title">Round {id}</div>
          <div className="tooltip__content_item">
            <div className="tooltip__label">Deposited:</div>
            <div className="tooltip__value">
              ${separateNum(data.tooltip.deposited)}
            </div>
          </div>
          <div className="tooltip__content_item">
            <div className="tooltip__label">Round volume:</div>
            <div className="tooltip__value">
              ${separateNum(data.tooltip.roundVolume)}
            </div>
          </div>
          <div className="tooltip__content_item">
            <div className="tooltip__label">Cap in DeXe:</div>
            <div className="tooltip__value">
              {separateNum(data.tooltip.cap)}
            </div>
          </div>
          <div className="tooltip__content_item">
            <div className="tooltip__label">Completed:</div>
            <div className="tooltip__value">{data.tooltip.completed}%</div>
          </div>
        </div>
      </ReactTooltip>
    </>
  )
}

export default function BarChart() {
  const staticChartData = [
    {
      percent: 100,
      tooltip: {
        deposited: 956201,
        roundVolume: 956202,
        cap: 1000000,
        completed: 100,
      },
    },
    {
      percent: 42,
      tooltip: {
        deposited: 256613,
        roundVolume: 603471,
        cap: 190476,
        completed: 42.52,
      },
    },
    {
      percent: 32,
      tooltip: {
        deposited: 50948,
        roundVolume: 404150,
        cap: 190476,
        completed: 12.61,
      },
    },
    {
      percent: 36,
      tooltip: {
        deposited: 58143,
        roundVolume: 365319,
        cap: 190475,
        completed: 15.92,
      },
    },
    {
      percent: 37,
      tooltip: {
        deposited: 55704,
        roundVolume: 326505,
        cap: 190475,
        completed: 17.07,
      },
    },
    {
      percent: 40,
      tooltip: {
        deposited: 55985,
        roundVolume: 281384,
        cap: 190475,
        completed: 19.9,
      },
    },
    {
      percent: 41,
      tooltip: {
        deposited: 71391,
        roundVolume: 349686,
        cap: 190475,
        completed: 20.42,
      },
    },
    {
      percent: 36,
      tooltip: {
        deposited: 52004,
        roundVolume: 319713,
        cap: 190475,
        completed: 16.28,
      },
    },
    {
      percent: 36,
      tooltip: {
        deposited: 39939,
        roundVolume: 252884,
        cap: 190475,
        completed: 15.79,
      },
    },
    {
      percent: 35,
      tooltip: {
        deposited: 65073,
        roundVolume: 265177,
        cap: 190475,
        completed: 24.54,
      },
    },
    {
      percent: 14,
      tooltip: {
        deposited: 12352,
        roundVolume: 298942,
        cap: 190475,
        completed: 4.13,
      },
    },
    {
      percent: 14,
      tooltip: {
        deposited: 12022,
        roundVolume: 284136,
        cap: 190475,
        completed: 4.23,
      },
    },
    {
      percent: 13,
      tooltip: {
        deposited: 9084,
        roundVolume: 281026,
        cap: 190475,
        completed: 3.23,
      },
    },
    {
      percent: 12,
      tooltip: {
        deposited: 4159,
        roundVolume: 254159,
        cap: 190475,
        completed: 1.64,
      },
    },
    {
      percent: 13,
      tooltip: {
        deposited: 7191,
        roundVolume: 260031,
        cap: 190475,
        completed: 3.05,
      },
    },
    {
      percent: 18,
      tooltip: {
        deposited: 21696,
        roundVolume: 281697,
        cap: 190475,
        completed: 7.7,
      },
    },
    {
      percent: 13,
      tooltip: {
        deposited: 7116,
        roundVolume: 277116,
        cap: 190475,
        completed: 2.57,
      },
    },
    {
      percent: 18,
      tooltip: {
        deposited: 23527,
        roundVolume: 290527,
        cap: 190475,
        completed: 8.1,
      },
    },
    {
      percent: 21,
      tooltip: {
        deposited: 33508,
        roundVolume: 298508,
        cap: 190475,
        completed: 11.23,
      },
    },
    {
      percent: 19,
      tooltip: {
        deposited: 25259,
        roundVolume: 286259,
        cap: 190475,
        completed: 8.82,
      },
    },
    {
      percent: 20,
      tooltip: {
        deposited: 30234,
        roundVolume: 300234,
        cap: 190475,
        completed: 10.07,
      },
    },
    {
      percent: 25,
      tooltip: {
        deposited: 47947,
        roundVolume: 319947,
        cap: 190475,
        completed: 14.99,
      },
    },
  ]

  return (
    <div className="bar-chart__container">
      <div className="bar-chart__top-label">Total: 1 896 911 USDC</div>
      <div className="bar-chart">
        {staticChartData.map((round, index) => {
          return (
            <React.Fragment key={round.tooltip.completed}>
              <Bar id={`${index + 1}`} data={round} />
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

Bar.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    percent: PropTypes.bool,
    tooltip: PropTypes.shape({
      deposited: PropTypes.number,
      roundVolume: PropTypes.number,
      cap: PropTypes.number,
      completed: PropTypes.number,
    }),
  }).isRequired,
}
