const separateNum = (num) => {
  const amount = Number(num)

  if (isNaN(amount)) {
    return 0
  }

  let negativeValue = false

  if (Math.sign(num) === -1) {
    num *= -1
    negativeValue = true
  }

  const numArr = num.toString().split(".")
  const minus = negativeValue ? "-" : ""

  return minus + numArr[0].split(/(?=(?:\d{3})+(?!\d))/).join(" ")
}

export { separateNum }
