import React from "react"
import PropTypes from "prop-types"

import { useWeb3Context } from "context/Web3Context"
import { separateNum } from "utils/helpers"
import "./style.scss"

const Row = ({ label, value }) => (
  <div className="row">
    <div className="row__label">{label}</div>
    <div className="row__value">{value}</div>
  </div>
)

export default function TokenStatistic() {
  const {
    totalSupply,
    priceFeed,
    UNIcurrentPrice,
    dexeContractBalance,
  } = useWeb3Context()

  const circulatingSupply = separateNum(
    (totalSupply - dexeContractBalance) / 10 ** 18
  )

  const marketCap =
    ((totalSupply - dexeContractBalance) / 10 ** 18) *
    Number(priceFeed.mxcDexeUsdt)

  return (
    <div className="token-statistic">
      <Row
        label="Token Supply:"
        value={`${separateNum(totalSupply / 10 ** 18)} DeXe`}
      />
      <Row label="Circulating Supply:" value={`${circulatingSupply} DeXe`} />
      <Row label="Market Cap:" value={`$${separateNum(marketCap)}`} />
    </div>
  )
}

Row.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}
